import * as ko from "knockout";
var Binding = /** @class */ (function () {
    function Binding() {
    }
    Binding.prototype.init = function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    };
    Binding.prototype.update = function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    };
    Binding.prototype.preprocess = function (value, name, addBinding) {
        return value;
    };
    Binding.prototype.unwrap = function (value) {
        return ko.utils.unwrapObservable(value);
    };
    Binding.isSubscribable = function (value) {
        return value.subscribe !== undefined;
    };
    Binding.register = function (bindingName, bindingType, supportsVirtualElements) {
        var bindingInstance = new bindingType();
        var binding = {
            init: bindingInstance.init.bind(bindingInstance),
            update: bindingInstance.update.bind(bindingInstance),
            preprocess: bindingInstance.preprocess.bind(bindingInstance)
        };
        ko.bindingHandlers[bindingName] = binding;
        if (supportsVirtualElements) {
            ko.virtualElements.allowedBindings[bindingName] = true;
        }
    };
    return Binding;
}());
export { Binding };
