var ObsoleteCallController = /** @class */ (function () {
    function ObsoleteCallController(context, callId) {
        this.context = context;
        this.callId = callId;
        if (context && !callId || !context && callId)
            throw "Set context together with callId";
        this.trackId = 0;
    }
    ObsoleteCallController.prototype.track = function () {
        if (this.context) {
            var trackId = this.context[this.callId];
            if (trackId === undefined) {
                trackId = 0;
            }
            this.context[this.callId] = trackId + 1;
            return trackId + 1;
        }
        else {
            this.trackId = this.trackId + 1;
            return this.trackId;
        }
    };
    ObsoleteCallController.prototype.accept = function (trackId) {
        var activeTrackId = this.context ? this.context[this.callId] : this.trackId;
        var accepted = trackId == activeTrackId;
        if (accepted) {
            if (this.context) {
                delete this.context[this.callId];
            }
            else {
                this.trackId = 0;
            }
        }
        return accepted;
    };
    return ObsoleteCallController;
}());
export { ObsoleteCallController };
Promise.prototype.takeLast = function (context, callId) {
    var callTracker = new ObsoleteCallController(context, callId);
    var trackId = callTracker.track();
    var originalPromise = this;
    return new Promise(function (resolve, reject) {
        originalPromise.then(function (result) { if (callTracker.accept(trackId))
            resolve(result); }, function (reason) { if (callTracker.accept(trackId))
            reject(reason); });
    });
};
