var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Binding } from "../binding";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
var TippyBinding = /** @class */ (function (_super) {
    __extends(TippyBinding, _super);
    function TippyBinding() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TippyBinding.prototype.init = function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var text = this.unwrap(valueAccessor());
        if ((text || '').length > 0) {
            tippy(element, {
                content: text,
                allowHTML: true
            });
        }
    };
    TippyBinding.prototype.update = function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var text = this.unwrap(valueAccessor());
        if ((text || '').length > 0) {
            tippy(element, {
                content: text,
                allowHTML: true
            });
        }
    };
    return TippyBinding;
}(Binding));
Binding.register('tippy', TippyBinding, true);
export default {};
