var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Binding } from "../binding";
var VirtualScrollBinding = /** @class */ (function (_super) {
    __extends(VirtualScrollBinding, _super);
    function VirtualScrollBinding() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VirtualScrollBinding.prototype.init = function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var _this = this;
        var value = valueAccessor();
        value.subscribe(function (newValues) {
            if (newValues == null || newValues.length == 0)
                return;
            var context = _this.getContext(element);
            var handler = context.handler;
            var haveMore = context.hasMore;
            var options = _this.unwrap(allBindings());
            var visibleSize = options.visibleSize || 10;
            if (newValues.length - visibleSize < 0 && _this.unwrap(haveMore)) {
                handler();
            }
        });
        element.addEventListener("scroll", function () {
            if (element[lastHeightKey] == element.scrollHeight)
                return;
            var context = _this.getContext(element);
            var hasMore = context.hasMore;
            if (element.scrollTop > (element.scrollHeight - element.offsetHeight - 200) && (element.scrollTop !== element.scrollHeight) && _this.unwrap(hasMore)) {
                element[lastHeightKey] = element.scrollHeight;
                var options = _this.unwrap(allBindings());
                window.setTimeout(function () { return delete element[lastHeightKey]; }, options.throttlingDelay || 1000);
                context.handler();
            }
        });
    };
    VirtualScrollBinding.prototype.update = function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var options = _super.prototype.unwrap.call(this, allBindings());
        var context = {
            hasMore: options.hasMore,
            handler: options.handler || (function () { })
        };
        this.setContext(element, context);
    };
    VirtualScrollBinding.prototype.getContext = function (element) {
        return element[contextDataKey] || {};
    };
    VirtualScrollBinding.prototype.setContext = function (element, context) {
        element[contextDataKey] = context;
    };
    return VirtualScrollBinding;
}(Binding));
var contextDataKey = "$virtualScrollBinding";
var lastHeightKey = contextDataKey + "_lastHeight";
Binding.register("virtualScroll", VirtualScrollBinding, true);
export default {};
