export var Either;
(function (Either) {
    function left(value) {
        return { tag: "left", value: value };
    }
    Either.left = left;
    function right(value) {
        return { tag: "right", value: value };
    }
    Either.right = right;
    function match(either, left, right) {
        switch (either.tag) {
            case "left": return left(either.value);
            case "right": return right(either.value);
        }
    }
    Either.match = match;
})(Either || (Either = {}));
