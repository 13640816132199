var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as Ladda from "ladda";
import { Binding } from "../binding";
var LaddaProgressBinding = /** @class */ (function (_super) {
    __extends(LaddaProgressBinding, _super);
    function LaddaProgressBinding() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LaddaProgressBinding.prototype.init = function (buttonElement, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var _this = this;
        var value = valueAccessor();
        if (Binding.isSubscribable(value)) {
            value.subscribe(function (newValue) { return _this.updateLadda(buttonElement, newValue); });
        }
        this.updateLadda(buttonElement, this.unwrap(value));
    };
    LaddaProgressBinding.prototype.getLadda = function (buttonElement) {
        var ladda = buttonElement[laddaKey];
        if (ladda != null)
            return ladda;
        if ($(buttonElement).is(":visible")) {
            ladda = Ladda.create(buttonElement);
            buttonElement[laddaKey] = ladda;
        }
        return ladda;
    };
    LaddaProgressBinding.prototype.updateLadda = function (buttonElement, value) {
        var ladda = this.getLadda(buttonElement);
        if (ladda == null)
            return;
        if (value) {
            ladda.start();
        }
        else {
            ladda.stop();
        }
    };
    return LaddaProgressBinding;
}(Binding));
var laddaKey = "$LaddaProgress_Ladda";
Binding.register("laddaProgress", LaddaProgressBinding, false);
export default {};
