var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as ko from "knockout";
import { Binding } from "../binding";
var BootstrapModalBinding = /** @class */ (function (_super) {
    __extends(BootstrapModalBinding, _super);
    function BootstrapModalBinding() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BootstrapModalBinding.prototype.init = function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var $element = $(element);
        var config = _super.prototype.unwrap.call(this, valueAccessor());
        var controller = config.controller;
        var options = { show: false, backdrop: false, keyboard: false };
        if (controller) {
            options = __assign(__assign({}, options), config.options);
        }
        else {
            controller = config;
        }
        if (options.zindex) {
            $element.css("z-index", parseInt(options.zindex) + 1);
        }
        if (options.replaceToContainer) {
            $(element).appendTo(options.replaceToContainer);
        }
        if (config.html) {
            $element.html(config.html);
        }
        $element.modal(options);
        var context = {
            isBound: false,
            viewModel: ko.observable(null)
        };
        controller.shown.subscribe(function (value) {
            $element.modal(value ? "show" : "hide");
            if (value) {
                if (options.zindex) {
                    $(".modal-backdrop").last().css("z-index", options.zindex);
                }
                context.viewModel(value);
                if (!context.isBound) {
                    var bindingContextExtension_1 = {
                        $modal: {
                            close: function () { controller.shown(null); }
                        }
                    };
                    var dialogBindingContext = bindingContext.createChildContext(context.viewModel, undefined, function (ctx) { return ctx.extend(bindingContextExtension_1); });
                    //const bindingContext = new (ko as any).bindingContext(context.viewModel, null, null, ctx => { ko.utils.extend(ctx, bindingContextExtension); });
                    ko.applyBindingsToDescendants(dialogBindingContext, element);
                    context.isBound = true;
                }
            }
        });
        $(element).on("hidden.bs.modal", function () {
            controller.shown(null);
        });
        return { controlsDescendantBindings: true };
    };
    return BootstrapModalBinding;
}(Binding));
var BootstrapModal = /** @class */ (function () {
    function BootstrapModal() {
        this.shown = ko.observable(undefined);
    }
    BootstrapModal.prototype.show = function (model) {
        var _this = this;
        if (!model.$modal) {
            model.$modal = { close: function () { return _this.close(); } };
        }
        this.shown(model);
    };
    BootstrapModal.prototype.showModal = function (model) {
        var _this = this;
        if (!model.$modal) {
            model.$modal = { close: function () { return _this.close(); } };
        }
        this.shown(model);
        return new Promise(function (resolve, reject) {
            model.setModalResult = function (result) {
                _this.close();
                resolve(result);
            };
        });
    };
    BootstrapModal.prototype.close = function () {
        this.shown(undefined);
    };
    return BootstrapModal;
}());
export { BootstrapModal };
;
Binding.register("bootstrapModal", BootstrapModalBinding, true);
