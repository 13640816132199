import "common";
import * as ko from "knockout";
import * as kv from "knockout.validation";
import * as toastr from "toastr";
var PageManager = /** @class */ (function () {
    function PageManager() {
        var _this = this;
        var whenReady = (function (onReady) {
            _this.callWhenReady(onReady);
        });
        whenReady.initAndBindTo = function (selector, config, initFunc) {
            _this.callWhenReady(function (exports) {
                kv.rules.pattern.message = 'Invalid.';
                kv.init({
                    registerExtenders: true,
                    messagesOnModified: true,
                    insertMessages: true,
                    parseInputAttributes: true,
                    messageTemplate: null,
                    decorateElement: true,
                    decorateInputElement: true,
                    errorMessageClass: 'invalid-feedback',
                    errorClass: 'is-invalid'
                }, true);
                toastr.options = {
                    "closeButton": false,
                    "debug": false,
                    "newestOnTop": false,
                    "progressBar": false,
                    "positionClass": "toast-bottom-right",
                    "preventDuplicates": false,
                    "onclick": null,
                    "showDuration": "300",
                    "hideDuration": "1000",
                    "timeOut": "5000",
                    "extendedTimeOut": "1000",
                    "showEasing": "swing",
                    "hideEasing": "linear",
                    "showMethod": "fadeIn",
                    "hideMethod": "fadeOut"
                };
                var init = initFunc || exports.init;
                if (init == null) {
                    throw "Init function can not be found";
                }
                ;
                var viewModel = init(config || {});
                var selectors = typeof selector == "string" ? [selector] : selector;
                selectors.forEach(function (s) {
                    var element = document.querySelector(s);
                    if (element == null)
                        return;
                    ko.applyBindings(viewModel || {}, element);
                });
            });
        };
        this.whenReady = whenReady;
    }
    PageManager.prototype.export = function (name, exports) {
        this.exports = exports;
        window[name] = this;
    };
    PageManager.prototype.callWhenReady = function (onReady) {
        var _this = this;
        document.addEventListener("DOMContentLoaded", function () {
            onReady(_this.exports || {});
        });
    };
    return PageManager;
}());
export default new PageManager();
