var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as ko from "knockout";
import { Binding } from "../binding";
var HtmlWithBindings = /** @class */ (function (_super) {
    __extends(HtmlWithBindings, _super);
    function HtmlWithBindings() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HtmlWithBindings.prototype.init = function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        return { controlsDescendantBindings: true };
    };
    HtmlWithBindings.prototype.update = function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        ko.utils.setHtml(element, valueAccessor());
        ko.applyBindingsToDescendants(bindingContext, element);
    };
    return HtmlWithBindings;
}(Binding));
Binding.register("htmlWithBindings", HtmlWithBindings, false);
export default {};
