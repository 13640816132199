var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as ko from "knockout";
import { registerComputed, computed } from "dkh-common";
var ObservableModel = /** @class */ (function () {
    function ObservableModel(config, model) {
        var _this = this;
        this._config = config;
        model = model || {};
        var validatable = [];
        this.eachKey(config, function (k) {
            var meta = config[k];
            if (Array.isArray(meta)) {
                var prop = (_this[k] = ko.observable(getOrDefault(model, k, meta[0])));
                if (meta.length > 1) {
                    prop = prop.extend(meta[1]);
                    validatable.push(prop);
                }
                return;
            }
            // ReSharper disable once InconsistentNaming
            _this[k] = new meta(getOrDefault(model, k, null));
        });
        this._scope = new ValidationScope(validatable);
    }
    ObservableModel.prototype.hideErrors = function () {
        this._scope.hideErrors();
    };
    ObservableModel.prototype.isValid = function () {
        if (!this._scope.isValid()) {
            this._scope.showErrors();
            return false;
        }
        return true;
    };
    ObservableModel.prototype.getRequestModel = function () {
        var _this = this;
        var r = {};
        this.eachKey(this._config, function (k) {
            var val = _this[k];
            r[k] = val.getRequestModel ? val.getRequestModel() : val();
        });
        return r;
    };
    ObservableModel.prototype.eachKey = function (obj, f) {
        for (var k in obj)
            f(k);
    };
    return ObservableModel;
}());
export { ObservableModel };
export function getOrDefault(model, key, def) {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    var cap = capitalizeFirstLetter(key);
    return has(model, key) ? model[key] : has(model, cap) ? model[cap] : def;
}
export function has(obj, property) {
    var hasOwnProperty = Object.prototype.hasOwnProperty;
    return hasOwnProperty.call(obj, property);
}
var ValidationScope = /** @class */ (function () {
    function ValidationScope(observables) {
        var _this = this;
        this.validatables = ko.observableArray([]);
        ko.utils.arrayForEach(observables, function (observable) {
            _this.add(observable);
        });
        this.errors = ko.observableArray([]);
    }
    ValidationScope.prototype.add = function (observable) {
        if (observable && !observable.nodeType) {
            if (!observable.isValid) {
                observable.extend({ validatable: true });
            }
            this.validatables.push(observable);
        }
        ;
    };
    ValidationScope.prototype.isValid = function () {
        var errors = [];
        ko.utils.arrayForEach(this.validatables(), function (observable) {
            if (!observable.isValid()) {
                errors.push(observable.error);
            }
        });
        this.errors(errors);
        return errors.length === 0;
    };
    ValidationScope.prototype.showErrors = function () {
        ko.utils.arrayForEach(this.validatables(), function (observable) {
            observable.isModified(true);
        });
    };
    ValidationScope.prototype.hideErrors = function () {
        ko.utils.arrayForEach(this.validatables(), function (observable) {
            observable.isModified(false);
        });
    };
    ValidationScope.prototype.validate = function (showErrors) {
        var isValid = this.isValid();
        if (showErrors && !isValid) {
            this.showErrors();
        }
        return isValid;
    };
    __decorate([
        computed
    ], ValidationScope.prototype, "isValid", null);
    ValidationScope = __decorate([
        registerComputed
    ], ValidationScope);
    return ValidationScope;
}());
export { ValidationScope };
