var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Binding } from "../binding";
import PerfectScrollbar from 'perfect-scrollbar';
var ScrollbarBinding = /** @class */ (function (_super) {
    __extends(ScrollbarBinding, _super);
    function ScrollbarBinding() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ScrollbarBinding.prototype.init = function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var options = valueAccessor();
        var enableX = options.x == true;
        var enableY = options.y == true;
        var scrollTo = options.scrollTo || null;
        var top = options.top || 0;
        $(element).addClass('with-scrollbar');
        if (enableX || enableY) {
            setTimeout(function () {
                new PerfectScrollbar(element, {
                    suppressScrollX: !enableX,
                    suppressScrollY: !enableY
                });
            }, 500);
            if ((scrollTo || '').length > 0 && $(scrollTo).length > 0) {
                setTimeout(function () {
                    $(element).animate({ scrollTop: $(scrollTo).position().top + top });
                }, 1000);
            }
        }
    };
    return ScrollbarBinding;
}(Binding));
Binding.register('scrollbar', ScrollbarBinding, true);
export default {};
