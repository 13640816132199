var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as ko from "knockout";
import { ValidationScope } from "../../../../common/ObservableModel";
import { registerComputed } from "dkh-common";
import BasePage from "../../layout/basePage";
var pwdParams = {
    required: true
}, usernameParams = {
    required: true
};
var LoginPage = /** @class */ (function (_super) {
    __extends(LoginPage, _super);
    function LoginPage(config) {
        var _this = _super.call(this, config) || this;
        _this.config = config;
        _this._scope = new ValidationScope([]);
        _this.rememberMe = ko.observable(config.rememberMe || false);
        _this.username = ko.observable(config.username || null).extend(usernameParams);
        _this._scope.add(_this.username);
        _this.password = ko.observable().extend(pwdParams);
        _this._scope.add(_this.password);
        return _this;
    }
    LoginPage.prototype.submit = function () {
        if (!this.isValid())
            return false;
        return true;
    };
    LoginPage.prototype.isValid = function () {
        if (!this._scope.isValid()) {
            this._scope.showErrors();
            return false;
        }
        return true;
    };
    LoginPage = __decorate([
        registerComputed
    ], LoginPage);
    return LoginPage;
}(BasePage));
export default LoginPage;
