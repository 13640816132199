import * as ko from "knockout";
var BasePage = /** @class */ (function () {
    function BasePage(config) {
        this.pageLoaded = ko.observable(false);
        this.darkMode = ko.observable(false);
        this.menuIsVisible = ko.observable(false);
        var theme = this.getCookie('dark-mode');
        if (theme === 'true') {
            this.darkMode(true);
        }
        this.pageLoaded(true);
    }
    BasePage.prototype.showMenu = function () {
        this.menuIsVisible(true);
    };
    BasePage.prototype.toggleTheme = function () {
        var darkMode = !this.darkMode();
        if (darkMode) {
            this.setCookie('dark-mode', 'true', 1);
        }
        else {
            this.eraseCookie('dark-mode');
        }
        this.darkMode(darkMode);
    };
    BasePage.prototype.hideMenu = function (data, event) {
        if ($(event.target).closest('.c-sidebar-nav').length === 0)
            this.menuIsVisible(false);
        return true;
    };
    BasePage.prototype.setCookie = function (name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    };
    BasePage.prototype.getCookie = function (name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) == 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    };
    BasePage.prototype.eraseCookie = function (name) {
        document.cookie = name + '=; Max-Age=-99999999;';
    };
    return BasePage;
}());
export default BasePage;
