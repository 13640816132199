var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as ko from "knockout";
import { Binding } from "../binding";
var ImageSourceBinding = /** @class */ (function (_super) {
    __extends(ImageSourceBinding, _super);
    function ImageSourceBinding() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ImageSourceBinding.prototype.init = function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var el = $(element);
        var image = element;
        if (image == null)
            return;
        var source = ko.utils.unwrapObservable(valueAccessor());
        var sources = typeof (source) === "string" ? [source] : source;
        if (sources.length === 0)
            return;
        var allBindings = this.unwrap(allBindingsAccessor());
        var stub = allBindings["errorStub"];
        var stubElement = stub == "next-sibling" ? element.nextElementSibling : null;
        var handleSourceError = allBindings["handleSourceError"];
        image.addEventListener("error", function () {
            var currentSourceIndex = el.data("current-source-index") || 0;
            if (currentSourceIndex + 1 < sources.length) {
                image.src = sources[currentSourceIndex + 1];
                el.data("current-source-index", currentSourceIndex + 1);
                return;
            }
            if (stubElement != null) {
                image.classList.remove("active");
                image.classList.add("inactive");
                stubElement.classList.remove("inactive");
                stubElement.classList.add("active");
            }
            if (handleSourceError != null)
                handleSourceError.call(viewModel);
        });
    };
    ImageSourceBinding.prototype.update = function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var image = element;
        if (image == null)
            return;
        image.classList.remove("inactive");
        image.classList.add("active");
        var source = this.unwrap(valueAccessor());
        var sources = typeof (source) === "string" ? [source] : source;
        if (sources.length === 0)
            return;
        image.src = sources[0];
    };
    return ImageSourceBinding;
}(Binding));
Binding.register("imageSource", ImageSourceBinding, true);
export default {};
