function jsonRequestTransformer(requestData, requestInit) {
    requestInit.method = "POST";
    requestInit.body = JSON.stringify(requestData);
    var headers = new Headers(requestInit.headers);
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    requestInit.headers = headers;
}
function jsonResponseTransformers(response) {
    return response.json();
}
function formUrlEncodedRequestTransformer(requestData, requestInit) {
    requestInit.method = "POST";
    var formData = Object.
        keys(requestData).
        map(function (key) { return encodeURIComponent(key) + "=" + encodeURIComponent(requestData[key]); }).
        join("&");
    requestInit.body = formData;
    var headers = new Headers(requestInit.headers);
    headers.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
    requestInit.headers = headers;
}
export var Transformers = {
    request: {
        json: jsonRequestTransformer,
        formUrlEncoded: formUrlEncodedRequestTransformer,
        resolve: function (format) {
            switch (format) {
                case "json": return jsonRequestTransformer;
                case "formUrlEncoded": return formUrlEncodedRequestTransformer;
                default: throw "Uknown request format";
            }
        }
    },
    response: {
        json: jsonResponseTransformers,
        resolve: function (format) {
            if (format === "json")
                return jsonResponseTransformers;
            throw "Unknown response format";
        }
    },
    service: {
        json: {
            requestTransformer: jsonRequestTransformer,
            responseTransaformer: jsonResponseTransformers
        },
        formUrlEncoded: {
            requestTransformer: formUrlEncodedRequestTransformer,
            responseTransaformer: jsonResponseTransformers
        }
    }
};
