import * as ko from "knockout";
ko.extenders.numericInput = function (target, options) {
    var result = ko.pureComputed({
        read: target,
        write: function (value) {
            if (value == null || value === "") {
                target(null);
                target.notifySubscribers(null);
                return;
            }
            var valueStr = (value || 0).toString();
            var changedValueStr = valueStr.replace(/,/g, ".").replace(/[^\d\.,\-]/g, "");
            var changedValue = parseFloat(changedValueStr);
            var newValue;
            if (isNaN(changedValue)) {
                newValue = "";
            }
            else {
                var roundingMultiplier = Math.pow(10, options.precision || 0);
                var changedValueRounded = Math.round((isNaN(changedValue) ? 0 : changedValue) * roundingMultiplier) / roundingMultiplier;
                newValue = (valueStr === changedValueStr && changedValueRounded === changedValue) ? value : changedValueRounded;
            }
            target(newValue);
            target.notifySubscribers(newValue);
        }
    }).extend({ notify: "always" });
    result(target());
    return result;
};
export default {};
