var QueryString = /** @class */ (function () {
    function QueryString(onPopState) {
        if (onPopState) {
            window.addEventListener('popstate', function (event) { return onPopState(event.state); });
        }
    }
    QueryString.prototype.parse = function (str) {
        if (typeof str !== 'string') {
            return {};
        }
        str = str.trim().replace(/^\?/, '');
        if (!str) {
            return {};
        }
        return str.trim().split('&').reduce(function (ret, param) {
            var parts = param.replace(/\+/g, ' ').split('=');
            var key = parts[0];
            var val = parts[1];
            key = decodeURIComponent(key);
            val = val === undefined ? null : decodeURIComponent(val);
            if (!ret.hasOwnProperty(key)) {
                ret[key] = val;
            }
            else if (Array.isArray(ret[key])) {
                ret[key].push(val);
            }
            else {
                ret[key] = [ret[key], val];
            }
            return ret;
        }, {});
    };
    QueryString.prototype.stringify = function (obj) {
        return obj ? Object.keys(obj).map(function (key) {
            var val = obj[key];
            if (Array.isArray(val)) {
                return val.map(function (val2) { return (encodeURIComponent(key) + '=' + encodeURIComponent(val2)); }).join('&');
            }
            return encodeURIComponent(key) + '=' + encodeURIComponent(val);
        }).join('&') : '';
    };
    QueryString.prototype.push = function (obj, pushState) {
        if (pushState === void 0) { pushState = true; }
        var oldParams = this.parse(location.search);
        var oldParamsString = this.stringify(oldParams);
        var newParams = {};
        for (var i = 0; i < obj.length; i++) {
            var kv = obj[i];
            newParams[kv.key] = kv.value;
        }
        var newParamsString = this.stringify(newParams);
        if (oldParamsString === newParamsString)
            return;
        if (pushState) {
            history.pushState(newParams, document.title, window.location.pathname + '?' + newParamsString);
        }
        else {
            history.replaceState(newParams, document.title, window.location.pathname + '?' + newParamsString);
        }
    };
    return QueryString;
}());
export { QueryString };
