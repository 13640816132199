var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as ko from "knockout";
import { Binding } from "../binding";
var StopBubbleBinding = /** @class */ (function (_super) {
    __extends(StopBubbleBinding, _super);
    function StopBubbleBinding() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StopBubbleBinding.prototype.init = function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        ko.utils.registerEventHandler(element, "click", function (event) {
            event.cancelBubble = true;
            if (event.stopPropagation)
                event.stopPropagation();
        });
    };
    return StopBubbleBinding;
}(Binding));
Binding.register("stopBubble", StopBubbleBinding, true);
export default {};
